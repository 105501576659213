import { Select } from "antd";
import { DefaultOptionType } from "antd/es/select";
import axios, { AxiosError } from "axios";
import { useCallback, useEffect, useState } from "react";
import { ProdottoInput, ProdottoOutput, UserLogged } from "./interfaces";

export const CreateMenu = (): JSX.Element => {

    const endpoint: string = process.env.NODE_ENV === 'development' ? 'https://localhost:7073' : 'https://menuapi.ottoetrequarti.com';

    const selectOptions: DefaultOptionType[] = [{ label: 'Congelato', value: 1 }, { label: 'Vegetariano', value: 2 }, { label: 'Biologico', value: 3 }]

    const defaultModel: ProdottoInput = {
        nome: "",
        descrizione: "",
        prezzo: 0,
        caratteristicheIds: []
    }

    const [model, setModel] = useState<ProdottoInput>(defaultModel);
    const [prodotti, setProdotti] = useState<ProdottoOutput[]>([]);
    const [prodottiLoaded, setProdottiLoaded] = useState<boolean>(false);

    const logout = () => {
        axios.post(`${endpoint}/User/Logout`, null, { withCredentials: true })
            .then(() => {
                window.location.href = "/";
            }).catch((error: AxiosError) => {
                console.log(error.message);
            });
    }

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        axios.post(`${endpoint}/Prodotto/Post`, model, { withCredentials: true })
            .then(() => {
                setProdottiLoaded(false);
            }).catch((error: AxiosError) => {
                console.log(error.message);
            });
    }

    const handleArrayChange = (values: string[]) => {
        let newValue: number[] = [];
        values.forEach((e: string) => {
            newValue.push(Number(e));
        })
        setModel({ ...model, caratteristicheIds: newValue })
    }

    const getProdotti = useCallback(() => {
        if (!prodottiLoaded) {
            axios.get<ProdottoOutput[]>(`${endpoint}/Prodotto/GetAll`)
                .then(({ data }) => {
                    setProdotti(data);
                    setProdottiLoaded(true);
                }).catch((error: AxiosError) => {
                    console.log(error.message);
                });
        }
    }, [endpoint, prodottiLoaded])

    const deleteProdotto = (id: number) => {
        axios.delete(`${endpoint}/Prodotto/Delete?id=${id}`, { withCredentials: true })
            .then(() => {
                setProdottiLoaded(false);
            }).catch((error: AxiosError) => {
                console.log(error.message);
            });
    }

    const changePosition = (id: number, isGoingUp: boolean) => {
        axios.post(`${endpoint}/Prodotto/ChangePosition`, { id, isGoingUp }, { withCredentials: true })
            .then(() => {
                setProdottiLoaded(false);
            }).catch((error: AxiosError) => {
                console.log(error.message);
            });
    }

    useEffect(() => {
        axios.get<UserLogged>(`${endpoint}/User/GetCurrentUser`, { withCredentials: true })
            .then(({ data }) => {
                if (data.id === -1) {
                    window.location.href = "/"
                }
            }).catch((error: AxiosError) => {
                console.log(error.message);
            });
    }, [endpoint])

    useEffect(() => {
        getProdotti();
    }, [prodotti, getProdotti])

    return (
        <div id="createPage">
            <div className="container">
                <div className="row mt-3">
                    <div className="basicSpacing col-6">
                        <strong style={{ fontSize: '1rem' }}>
                            OTTOETREQUARTI
                        </strong>
                        <p style={{ fontSize: '0.8rem' }}>MODIFICA MENU</p>
                    </div>
                    <div className="col-6">
                        <div className="d-flex justify-content-end">
                            <span style={{ cursor: 'pointer' }} onClick={() => logout()}>Logout</span>
                        </div>
                    </div>
                </div>
                <div>
                    <form onSubmit={(e: React.FormEvent<HTMLFormElement>) => submit(e)}>
                        <div className="form-group mt-3">
                            <input
                                type="text"
                                className="form-control"
                                id="username"
                                placeholder="Nome del piatto"
                                onChange={(e: any) => setModel({ ...model, nome: e.target.value })}
                                required
                            />
                        </div>
                        <div className="form-group mt-3">
                            <textarea
                                rows={3}
                                id="username"
                                className="form-control"
                                placeholder="Descrizione del piatto"
                                onChange={(e: any) => setModel({ ...model, descrizione: e.target.value })}
                            />
                        </div>
                        <div className="row mt-3">
                            <div className="col-8">
                                <div className="form-group">
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{ width: '100%' }}
                                        placeholder="Seleziona una categoria del piatto"
                                        onChange={handleArrayChange}
                                        options={selectOptions}
                                    />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">€</span>
                                    </div>
                                    <input
                                        type="number"
                                        min={0}
                                        className="form-control"
                                        id="prezzo"
                                        onChange={(e: any) => setModel({ ...model, prezzo: Number(e.target.value) })}
                                        required
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center m-2">
                            <button type="submit" className="btn btn-primary" disabled={!prodottiLoaded}>{prodottiLoaded ? 'Crea' : '...'}</button>
                        </div>

                    </form>
                </div>
                <div>
                    {prodotti.map((e: ProdottoOutput, i: number) => (
                        <div key={i} className="p-2">
                            <div className="row border-bottom py-3">
                                <div className="col-2">
                                    {(i !== 0) && (
                                        <div style={{ cursor: 'pointer' }} onClick={() => changePosition(e.id, true)}>
                                            <i className="fa-solid fa-arrow-up"></i>
                                        </div>
                                    )}
                                    {(i !== prodotti.length - 1) && (
                                        <div style={{ cursor: 'pointer' }} onClick={() => changePosition(e.id, false)}>
                                            <i className="fa-solid fa-arrow-down"></i>
                                        </div>
                                    )}
                                </div>
                                <div className="col-6">
                                    <strong>{e.nome}</strong>
                                    <p>{e.descrizione}</p>
                                    {e.caratteristicheIds.map((e: number, i: number) => (
                                        <small key={i}>{selectOptions.filter(opt => opt.value === e)[0].label} </small>
                                    ))}
                                </div>
                                <div className="col-4">
                                    <div className="d-flex justify-content-end mb-1">
                                        <strong>{e.prezzo}€</strong>
                                    </div>
                                    <div className="d-flex justify-content-end mb-1">
                                        <button className="btn btn-warning" style={{ cursor: 'pointer' }} onClick={() => window.location.href = `/edit?id=${e.id}`}>Modifica</button>
                                    </div>
                                    <div className="d-flex justify-content-end mb-1">
                                        <button className="btn btn-danger" style={{ cursor: 'pointer' }} onClick={() => deleteProdotto(e.id)}>Elimina</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}