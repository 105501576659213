import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { UserInput, UserLogged } from "./interfaces";

export const Login = (): JSX.Element => {

    const endpoint: string = process.env.NODE_ENV === 'development' ? 'https://localhost:7073' : 'https://menuapi.ottoetrequarti.com';

    const [model, setModel] = useState<UserInput>({
        username: "",
        password: "",
        rememberMe: false,
    });

    useEffect(() => {
        axios.get<UserLogged>(`${endpoint}/User/GetCurrentUser`, { withCredentials: true })
            .then(({ data }) => {
                if (data.id !== -1) {
                    window.location.href = "/createMenu"
                }
            }).catch((error: AxiosError) => {
                console.log(error.message);
            });
    }, [endpoint])

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        axios.post(`${endpoint}/User/Login`, model, { withCredentials: true })
            .then(() => {
                window.location.href = "/createMenu"
            }).catch((error: any) => {
                console.log(error.toString());
            });
    }

    return (
        <div id="loginPage">
            <div className="container">
                <div className="d-flex justify-content-center align-items-center mt-5">
                    <form className="bg-white p-3 rounded" onSubmit={(e: React.FormEvent<HTMLFormElement>) => submit(e)}>
                        <h1>Login</h1>
                        <div className="form-group mt-3">
                            <label htmlFor="username">Username</label>
                            <input type="text" className="form-control" id="username" placeholder="Username" value={model.username} onChange={e => setModel({ ...model, username: e.target.value })} />
                        </div>
                        <div className="form-group mt-3">
                            <label htmlFor="password">Password</label>
                            <input type="password" className="form-control" id="password" placeholder="Password" value={model.password} onChange={e => setModel({ ...model, password: e.target.value })} />
                        </div>
                        <div className="form-check mt-3">
                            <label className="form-check-label" htmlFor="rememberMe">Ricordami</label>
                            <input type="checkbox" className="form-check-input" id="rememberMe" checked={model.rememberMe} onChange={e => setModel({ ...model, rememberMe: e.target.checked })} />
                        </div>
                        <div className="d-flex justify-content-center m-2">
                            <button type="submit" className="btn btn-primary">Entra</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}