import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { ProdottoOutput } from "./interfaces";

import './style/index.css';

export const App = (): JSX.Element => {

  const endpoint: string = process.env.NODE_ENV === 'development' ? 'https://localhost:7073' : 'https://menuapi.ottoetrequarti.com';

  const [prodotti, setProdotti] = useState<ProdottoOutput[]>([]);
  const [prodottiLoaded, setProdottiLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!prodottiLoaded) {
      axios.get(`${endpoint}/Prodotto/GetAll`)
        .then(({ data }) => {
          setProdotti(data);
          setProdottiLoaded(true);
        }).catch((error: AxiosError) => {
          console.log(error.message);
        })
    }
  }, [endpoint, prodottiLoaded])

  return (
    <div id="App" className="bg-main text-main">
      <div className="min-vh-100">
        <div className="container">

          <div className="d-flex justify-content-center align-items-center min-vh-100">
            <div className="row text-center">

              <div className="imgDiv col-12" style={{
                backgroundImage: `url('${process.env.PUBLIC_URL}/static/Logo.png')`,
                padding: '50%'
              }} />
              <div className="col-12">
                <div id="title" className="mt-5">
                  <p>RESTAURANT & PIZZA</p>
                  <p><strong>MENU</strong>PRANZO</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="imgDiv col-12" style={{
          backgroundImage: `url('${process.env.PUBLIC_URL}/static/banners/BannerTop.jpg')`,
          padding: '50%'
        }} />

        <div className="container px-4">

          <div className="mt-5">
            {prodotti.map((e, i: number) => (
              <>
                <div className="row mt-4" key={i}>
                  <div className="col-12">

                    <div className="col-2 col-md-1 d-flex">
                      {e.caratteristicheIds.map((id, c) => (
                        <div key={c}>
                          {id === 1 && (
                            <img alt="froIcon" className="typeIcons me-1" src={`${process.env.PUBLIC_URL}/static/icons/fro.svg`} />
                          )}
                          {id === 2 && (
                            <img alt="vegIcon" className="typeIcons me-1" src={`${process.env.PUBLIC_URL}/static/icons/veg.svg`} />
                          )}
                          {id === 3 && (
                            <img alt="bioIcon" className="typeIcons me-1" src={`${process.env.PUBLIC_URL}/static/icons/bio.svg`} />
                          )}
                        </div>
                      ))}
                    </div>
                    <div className="col-8" />

                  </div>
                  <div className="col-10">
                    <h4><strong>{e.nome}</strong></h4>
                  </div>
                  <div className="col-2 d-flex flex-row-reverse">
                    <h4><strong>{e.prezzo}</strong></h4>
                  </div>
                  <div className="col-12">
                    <p>{e.descrizione}</p>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>

        <div className="imgDiv col-12" style={{
          backgroundImage: `url('${process.env.PUBLIC_URL}/static/banners/BannerBottom.jpg')`,
          padding: '50%'
        }} />

        <div className="container">

          <div className="my-5 py-5">
            <div id="grazie" className="imgDiv" style={{
              backgroundImage: `url('${process.env.PUBLIC_URL}/static/Grazie.png')`,
              padding: '10%',
              marginBottom: '50%'
            }} />
          </div>

          <div className="px-4" id="textWall">

            <div className="mb-5">
              <div>
                <img alt="vegIcon" className="typeIcons me-2" src={`${process.env.PUBLIC_URL}/static/icons/veg.svg`} />
                <span>Vegetariano</span>
              </div>
              <div>
                <img alt="bioIcon" className="typeIcons me-2" src={`${process.env.PUBLIC_URL}/static/icons/bio.svg`} />
                <span>Biologico o con ingredienti biologici</span>
              </div>
              <div>
                <img alt="froIcon" className="typeIcons me-2" src={`${process.env.PUBLIC_URL}/static/icons/fro.svg`} />
                <span>Alcuni ingredienti potrebbero essere surgelati</span>
              </div>
            </div>

            <div>
              <strong>Si avvisa la gentile clientela che negli alimenti e nelle bevande preparati e somministrati in questo esercizio, possono essere contenuti ingredienti o coadiuvanti considerati allergeni.</strong>
            </div>
            <div className="mt-2">
              <p className="fw-light">ELENCO DELLE SOSTANZE O PRODOTTI CHE PROVOCANO ALLERGIE O INTOLLERANZE UTILIZZATI IN QUESTO ESERCIZIO E PRESENTI NELL'ALLEGATO DEL REG. UE N. 1169/2011</p>
            </div>
            <div>
              <p>1 - CEREALI CONTENENTI GLUTINE, CIOE GRANO, SEGALE, ORZO, AVENA, FARRO, KAMUIT O I LORO DERIVATI</p>
            </div>
            <div>
              <p>2 - CROSTACEI E PRODOTTI A BASE DI CROSTACEI</p>
            </div>
            <div>
              <p>3 - UOVA E PRODOTTI A BASE DI UOVA</p>
            </div>
            <div>
              <p>4 - PESCE E PRODOTTI A BASE DI PESCE</p>
            </div>
            <div>
              <p>5 - ARACHIDI E PRODOTTI A BASE DI ARACHIDI</p>
            </div>
            <div>
              <p>6 - SOIA E PRODOTTI A BASE DI SOIA</p>
            </div>
            <div>
              <p>7 - LATTE E PRODOTTI A BASE DI LATTE (INCLUSO LATTOSIO)</p>
            </div>
            <div>
              <p>8 - FRUTTA A GUSCIO, VALE A DIRE MANDORLE, NOCCIOLE, NOCI, NOCI DI AGAGIÙ, NOCI DI PECAN, NOCI DEL BRASILE, PISTACCHI, NOCI DI MACADAMIA, NOCI DEL QUEENSLAND E I LORO PRODOTTI</p>
            </div>
            <div>
              <p>9 - SEDANO E PRODOTTI A BASE DI SEDANO</p>
            </div>
            <div>
              <p>10 - SENAPE E PRODOTTI A BASE DI SENAPE</p>
            </div>
            <div>
              <p>11 - SEMI DI SESAMO E PRODOTTI A BASE DI SEMI DI SESAMO</p>
            </div>
            <div>
              <p>12 - ANIDRIDE SOLFOROSA E SOLFITI IN CONCENTRAZIONI SUPERIORI A 10MG/KG</p>
            </div>
            <div>
              <p>13 - LUPINI E PRODOTTI A BASE DI LUPINI</p>
            </div>
            <div>
              <p>14 - MOLLUSCHI E PRODOTTI A BASE DI MOLLUSCHI</p>
            </div>
            <div>
              <p>Le informazioni circa la presenza di sostanze o prodotti che provocano allergie o intolleranze sono disponibili rivolgendosi al personale in servizio ed è possibile consultare l'apposita documentazione che verrà fornita su richiesta.</p>
            </div>
            <div>
              <strong>Tutti i prezzi sono espressi in euro.</strong>
            </div>

          </div>

          <div className="py-5">
            <a className="text-decoration-none" href="https://slowmotion.to/">
              <div className="imgDiv" style={{
                backgroundImage: `url('${process.env.PUBLIC_URL}/static/LogoSlowmotion.png')`,
                padding: '0.7rem'
              }} />
            </a>
          </div>

        </div>
      </div>
    </div>
  );
}