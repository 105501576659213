import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { App } from './App';
import { CreateMenu } from './CreateMenu';
import { Edit } from './Edit';
import { Login } from './Login';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<App />} />
        <Route path='/login' element={<Login />} />
        <Route path='/createMenu' element={<CreateMenu />} />
        <Route path='/edit' element={<Edit />} />
        <Route path='*' element={
          <div className='text-center mt-5'>
            <p>404 - NOT FOUND</p>
          </div>
        } />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);