import { Select } from "antd";
import { DefaultOptionType } from "antd/es/select";
import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ProdottoInput, UserLogged } from "./interfaces";

export const Edit = (): JSX.Element => {

    const endpoint: string = process.env.NODE_ENV === 'development' ? 'https://localhost:7073' : 'https://menuapi.ottoetrequarti.com';

    const defaultModel: ProdottoInput = {
        nome: "",
        descrizione: "",
        prezzo: 0,
        caratteristicheIds: []
    }

    const selectOptions: DefaultOptionType[] = [{ label: 'Congelato', value: 1 }, { label: 'Vegetariano', value: 2 }, { label: 'Biologico', value: 3 }]

    const [params] = useSearchParams();
    const [model, setModel] = useState<ProdottoInput>(defaultModel);

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        axios.put(`${endpoint}/Prodotto/Put?id=${params.get('id')}`, model, { withCredentials: true })
            .then(() => {
                window.location.href = "/createMenu";
            }).catch((error: AxiosError) => {
                console.log(error.message);
            });
    }

    const handleArrayChange = (values: number[]) => {
        let newValue: number[] = [];
        values.forEach((e: number) => {
            newValue.push(Number(e));
        })
        setModel({ ...model, caratteristicheIds: newValue })
    }

    useEffect(() => {
        axios.get<UserLogged>(`${endpoint}/User/GetCurrentUser`, { withCredentials: true })
            .then(({ data }) => {
                if (data.id === -1) {
                    window.location.href = "/"
                }
            }).catch((error: AxiosError) => {
                console.log(error.message);
            });
    }, [endpoint])

    useEffect(() => {
        if (params) {
            axios.get<ProdottoInput>(`${endpoint}/Prodotto/GetById?id=${params.get('id')}`)
                .then(({ data }) => {
                    setModel(data);
                }).catch((error: AxiosError) => {
                    console.log(error.message);
                })
        }
    }, [params, endpoint])

    return (
        <div id="editName">

            <div className="container">
                <div className="mt-5 text-center">
                    <h3>Modifica il prodotto</h3>
                </div>
                <form onSubmit={(e: React.FormEvent<HTMLFormElement>) => submit(e)}>
                    <div className="form-group mt-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Nome del piatto"
                            value={model.nome}
                            onChange={(e: any) => setModel({ ...model, nome: e.target.value })}
                            required
                        />
                    </div>
                    <div className="form-group mt-3">
                        <textarea
                            rows={3}
                            className="form-control"
                            placeholder="Descrizione del piatto"
                            value={model.descrizione}
                            onChange={(e: any) => setModel({ ...model, descrizione: e.target.value })}
                        />
                    </div>
                    <div className="row mt-3">
                        <div className="col-8">
                            <div className="form-group">
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Seleziona una categoria del piatto"
                                    onChange={handleArrayChange}
                                    options={selectOptions}
                                    value={model.caratteristicheIds}
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">€</span>
                                </div>
                                <input
                                    type="number"
                                    min={0}
                                    className="form-control"
                                    value={model.prezzo}
                                    onChange={(e: any) => setModel({ ...model, prezzo: Number(e.target.value) })}
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center m-2">
                        <a className="btn btn-secondary me-2" href="/createMenu">Torna indietro</a>
                        <button type="submit" className="btn btn-primary">Modifica</button>
                    </div>

                </form>
            </div>
        </div>
    );
}